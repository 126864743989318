<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pm-0 ">
        <div class="jumbotron bg-light">
          <h5 class="space-x-3" v-if="!$store.getters.shouldReload">
            <label class="text-muted">رقم الفاتورة الحالي: </label>
            <label>{{ !!config ? config.current_invoice_number : 'غير محدد' }}</label>
            <tooltip title="تعديل">
              <i class="fa fa-edit text-info fa-1x pointer" data-toggle="modal" :data-target="`#${storeId}`"></i>
            </tooltip>
          </h5>
        </div>
      </div>
    </div>

    <teleport to="#app">
      <confirm-model
        :target-id="storeId"
        title="تعديل رقم الفاتورة الحالي"
        confirm-text="تعديل"
        form="store">
        <form @submit.prevent="store" id="store">
          <div class="form-group">
            <label>رقم الفاتورة الحالي</label>
            <FormInput id="current_invoice_number" placeholder="رقم الفاتورة الحالي" :value="!!config ? config.current_invoice_number : ''" />
          </div>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel"
import FormInput from "@/components/Forms/FormInput"
import Tooltip from "@/components/UI/Tooltip"

export default {
  name: "InvoiceConfig",
  components: {Tooltip, FormInput, ConfirmModel},
  data() {
    return {
      config: ''
    }
  },
  computed: {
    defaultUrl() {
      return '/invoice-configs'
    },
    storeId() {
      return 'invoice-config-store'
    }
  },
  methods: {
    async setInvoiceConfig() {
      const response = await http.send(this.defaultUrl, {_method: 'GET'})
      if (http.responseAccepted(response, 'config')) {
        this.config = response.data.config
      }
    },
    async store(e) {
      const formData = new FormData(e.target)

      if (!formData.get('current_invoice_number'))
        return http.errorMessage(messages.checkFields)

      let url = this.defaultUrl
      if (!!this.config) {
        formData.set('_method', 'PUT')
        url = `${url}/${this.config.id}`
      }

      const response = await http.send(url, formData)

      if (http.responseAccepted(response, 'config')) {
        this.config = response.data.config
        this.hideModal(`#${this.storeId}`)
      }
    }
  },
  async created() {
    await this.setInvoiceConfig();
  }
}
</script>